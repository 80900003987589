import react from 'react';
import '../Certificados/CardsCert.css';
import CardItemCert from './CardItemCert'
import PMEL from '../PDF/docs/PMELider.pdf';
import TOP from '../PDF/docs/TOP5.pdf'
import PMEex from '../PDF/docs/Certificado_PMEEx.pdf'
import IATF from '../PDF/docs/PLASTIFA_IATF.pdf'
import ISO from '../PDF/docs/ISO_9001.pdf'
import ISO14001 from '../PDF/docs/14001.pdf'

function CardsCert() {
  return (
    <div className='cards2'>
      <div>
        <h1 className='colorcert' style={{marginTop:'10px', marginBottom:'10px'}}>Certificados</h1>
      </div>
     
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            
          <CardItemCert
             path={TOP}
             src='images/TOP5SCORING.png'
             text='TOP 5%'
            />

            <CardItemCert
              path={PMEex}
              src='images/PMEex.png'
              text='PME Exc.'
            />

            <CardItemCert
              path={PMEL}
              src='images/PMELider.png'
              text='PME Líder'
            />

            <CardItemCert
              path={IATF}
              src='/images/IATF16949.png'
              text='IATF 16949'
            />
          
            <CardItemCert
              path={ISO}
              src='images/ISO9001.png'
              text='ISO 9001'
            />
            <CardItemCert
              path={ISO14001}
              src='/images/ISO14001.png'
              text='ISO 14001'
            />
          </ul>
          
        </div>
      </div>
    </div>
  );
}

export default CardsCert;
